import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import * as selectors from 'UserApp/store/user/selectors'
import * as actions from 'UserApp/store/user/actions'
import * as mixpanel from 'lib/mixpanel'
import api from 'lib/api'

function useUser() {
  const dispatch = useDispatch()
  const user = useSelector(selectors.selectUser)

  return {
    user,
    update: async (data:any) => {
      const response = await api.patch('/api/users/me', data)

      dispatch(actions.updateSimple(response.data))
      return response
    },

    downgradeSubscription: async () => {
      const response = await api.patch('/api/users/me/subscription/downgrade')

      mixpanel.downgradeSubscription()

      dispatch(actions.downgradeSubscriptionSimple(response.data))

      return response
    },

    reactivateSubscription: async () => {
      const response = await api.patch('/api/users/me/subscription/reactivate')

      dispatch(actions.reactivateSubscription(response.data))

      return response
    },

    deleteAccount: async (data: any) => {
      await api.patch('/api/users/me/subscription/cancel', {
        deleteAllMyData: true,
        ...data,
      })

      mixpanel.deleteAccount()

      Cookies.remove('token')

      dispatch(actions.logoutSimple())
    },
  }
}

export default useUser
