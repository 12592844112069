import React from 'react'
import { connect } from 'react-redux'
import CancelReplacementIntellitagOrderModal from './CancelReplacementIntellitagOrderModal'

function CancelReplacementIntellitagOrderModalContainer(props: any) {
  const { match } = props
  const { petId, orderId } = match.params
  function handleClose() {
    props.history.push(`/`)
  }
  return <CancelReplacementIntellitagOrderModal {...props} petId={petId} orderId={orderId} onClose={handleClose} />
}
export default connect()(CancelReplacementIntellitagOrderModalContainer)

