import { Header } from '@pet-notify/ui'
import { Loader } from '@pet-notify/ui'
import { FieldGroup, Fields, Label, Input, Button, Text } from '@pet-notify/ui'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'
import React, { useEffect, useState } from 'react'


type Props = {
    onClose: () => void
}

type FormData = {
    alarmGraceMinutes: number
    alarmGraceHours?: number
}

const ChangeAlarmGracePeriodForm = ({ onClose }: Props) => {
    const { setNotification } = useNotification()
    const { user, update } = useUser()

    const [hours, setHours] = useState<any>()
    const [minutes, setMinutes] = useState<any>()

    useEffect(() => {
        const totalMinutes = user.alarmGraceMinutes || 0

        if (totalMinutes < 60) {
            setHours(0)
            setMinutes(totalMinutes)
        } else {
            setHours(totalMinutes ? Math.floor(totalMinutes / 60) : 0)
            setMinutes(totalMinutes ? totalMinutes % 60 : 0)
        }
    }, [user])

    const { register,getValues, reset, ...form } = useForm<FormData>()

    useEffect(() => {
        if (hours !== undefined && minutes !== undefined) {
            reset({
                alarmGraceHours: hours,
                alarmGraceMinutes: minutes,
            });
        }
    }, [hours, minutes, reset]);

    const { mutate, isLoading, error, isError }:any = useMutation(
        (data: FormData) => update(data),
        {
            onSuccess: () => {
                setNotification('Alarm grace period changed')
                onClose()
            },
        },
    )

    const handleSubmit = form.handleSubmit((values) => {
        const totalMinutes = (Number(values.alarmGraceHours) || 0) * 60 + (Number(values.alarmGraceMinutes) || 0);
        if (totalMinutes < 5) {
            setNotification('Alarm grace period must be at least 5 minutes.');
            return;
        } else if (totalMinutes > 1440) {
            setNotification('Alarm grace period must be less than 24 hours.');
            return;
        }
        mutate({alarmGraceMinutes: totalMinutes})
    })

    if (isLoading) {
        return <Loader />
    }

    return (
        <form onSubmit={handleSubmit}>
            <Header mb='6'>Change alarm grace period</Header>
            <Fields>
                <FieldGroup>
                    <Label>Hours</Label>
                    <Input type='number' {...register('alarmGraceHours')} />
                </FieldGroup>
                <FieldGroup>
                    <Label>Minutes</Label>
                    <Input type='number' {...register('alarmGraceMinutes')} />
                </FieldGroup>
            </Fields>
            <Button type='submit'>Change alarm grace period</Button>
        </form>
    )
}

export default ChangeAlarmGracePeriodForm