import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import {
  create,
  fetchOne,
  fetchPricing,
  update,
  checkout,
  cancelOrder,
  cancel,
  cancelFreshOrder,

} from 'UserApp/services/replace-intellitag-orders'
import type {
  CheckoutFormData,
  ReplaceIntellitagOrder,
  ReplaceIntellitagOrderPricing,
  RequestData,
} from 'UserApp/services/replace-intellitag-orders/types'

export function useReplaceIntellitagOrder(id?: string | null, options: UseQueryOptions<ReplaceIntellitagOrder> = {}) {
  return useQuery(['replace-intellitag-order', id], async () => {
    if (!id) throw new Error('No ID provided')
    return fetchOne(id)
  }, {
    enabled: !!id,
  })
}

export function useCreateReplaceIntellitagOrder() {
  const queryClient = useQueryClient()

  return useMutation((data: RequestData) => create(data), {
    onSuccess: (data) => {
      queryClient.setQueryData(['replace-intellitag-order', data.id], data)
    },
  })
}

export function useUpdateReplaceIntellitagOrder(id: string) {
  const queryClient = useQueryClient()

  return useMutation((data: RequestData) => update(id, data), {
    onSuccess: (data) => {
      queryClient.setQueryData(['replace-intellitag-order', data.id], data)
    },
  })
}

export function useCheckoutReplaceIntellitagOrder(id: string) {
  const queryClient = useQueryClient()

  return useMutation(
    (data: CheckoutFormData) => {
      return checkout(id, data)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['replace-intellitag-order', data.id], data)
      },
    },
  )
}

export function useCancelReplaceIntellitagOrder() {
  const queryClient = useQueryClient()
  return useMutation((id: string) => {
      return cancel(id)
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(['replace-intellitag-order'])
      },
    },
  )
}

export function useCancelIntellitagOrder(id: string) {
  const queryClient = useQueryClient()
  return useMutation(
    () => {
      return cancelOrder(id)
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(['replace-intellitag-order', id])
      },
    },
  )
}

export function useCancelFreshOrder(id: string) {
  const queryClient = useQueryClient()
  return useMutation(
    () => {
      return cancelFreshOrder(id)
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(['replace-intellitag-order', id])
      },
    },
  )
}
// export function useReplaceIntellitagOrderPricing(id: string) {
//   return useQuery(['replace-intellitag-order-pricing', id], () =>
//     fetchPricing(id),
//     // enabled: !!id
//   )
// }
