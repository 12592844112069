import React from 'react'
import { Link } from 'react-router-dom'

import style from './style.module.scss'

type Props = {
  id: number
  name: string
  intellitagId: null | number
  photo?: {
    sizes: {
      large: {
        src: string
      }
    }
  }
  hasPendingOrder?: boolean
  hasPendingReplacementOrder?: boolean
  intellitags: [
    {
      id: number,
      pendingReplacementOrder: number | null
    }
  ]
  pendingOrders: [
    {
      id: number,
      pendingReplacementOrder: number | null,
      orderId: number
    }
  ]
  meta?: {
    intellitagInstructions?: boolean
  }
  inactiveIntellitag?: boolean
}

function Pet(props: Props) {
  const {
    id,
    name,
    photo,
    hasPendingOrder,
    hasPendingReplacementOrder,
    meta,
    intellitags,
    pendingOrders,
    intellitagId,
    inactiveIntellitag,
  } = props
  const pendingReplaceOrderIds = intellitags.filter(intellitag => intellitag.pendingReplacementOrder !== null).map(intellitag => intellitag.pendingReplacementOrder)
  const pendingOrderIds = pendingOrders?.map(order => order.orderId)

  return (
    <>
      <Link to={`/pets/${id}`} className={style.wrapper}>
        {photo ? (
          <img src={photo.sizes.large.src} />
        ) : (
          <div className={style.placeholder}>Add photo</div>
        )}

        <div className={style.inner}>
          {name}{' '}
          {intellitagId &&
            !inactiveIntellitag &&
            (!photo || !meta.intellitagInstructions) && (
              <span className={style.alert}>!</span>
            )}
        </div>
      </Link>
      {pendingOrders?.length > 0 && intellitags.length < 1 && !inactiveIntellitag && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={style.orderPendingWrapper}>
            <div className={style.orderPending}>
              <span>Order Pending</span>
            </div>
            <div className={style.cancelOrderWrapper}>
              <Link
                to={`/cancel-intellitag-order/${id}/${[...new Set(pendingOrderIds)].join(',')}`}
                className={style.cancelOrder}
              >
                Cancel Order
              </Link>
            </div>
          </div>
          {/* <Link
            className={style.activateIntellitagLink}
            to={`/pets/activate-intellitag?petId=${id}`}
            style={{ paddingTop: '0px' }}
          >
            Order, Replace or Activate Intellitag
          </Link> */}
        </div>
      )}
      {intellitagId && !inactiveIntellitag && (
        <div className={style.orderPendingWrapper}>
          {
            hasPendingReplacementOrder ? (
              <div className={style.orderPending}>Replacement Order Pending</div>
            ) : (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {intellitags.length > 0 && <div className={style.activatedIntellitag}>{intellitags.length > 1 ? intellitags.length : ""} {intellitags.length > 1 ? 'Intellitags' : 'Intellitag'} Activated</div>}
                  {(intellitags?.length + pendingOrders?.length) < 3 && <Link
                    className={style.activateIntellitagLink}
                    to={`/pets/activate-intellitag?petId=${id}`}
                    style={{ paddingTop: '0px' }}
                  >
                    Order, Replace or Activate Intellitag
                  </Link>}
                </div>

                {/* {pendingOrders.length > 0 && (
                  <div className={style.cancelOrderWrapper}>
                    <Link
                      to={`/cancel-intellitag-order/${id}/${[...new Set(pendingOrderIds)].join(',')}`}
                      className={style.cancelOrder}
                    >
                      Cancel Order
                    </Link>
                  </div>
                )} */}
              </div>
            )
          }

          {
            pendingReplaceOrderIds.length > 0 && <div className={style.cancelOrderWrapper}> <Link to={`/cancel-intellitag-replacement-order/${id}/${pendingReplaceOrderIds.join(',')}`} className={style.cancelOrder}>
              Cancel Replacement Order
            </Link>
            </div>
          }
        </div>
      )}
      {inactiveIntellitag && (
        <Link
          to={`/pets/activate-intellitag/reactivate`}
          className={style.activateIntellitagLink}
        >
          Reactivate Intellitag
        </Link>
      )}

      {!intellitagId && !hasPendingOrder && (
        <Link
          className={style.activateIntellitagLink}
          to={`/pets/activate-intellitag?petId=${id}`}
        >
          Order, Replace or Activate Intellitag
        </Link>
      )}
    </>
  )
}

export default Pet