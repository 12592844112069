import parsePhoneNumberFromString from "libphonenumber-js"

export const validatePhoneNumber = (inputNumber: string) => {
    const phoneNumber = parsePhoneNumberFromString(inputNumber, 'US')

    if (!phoneNumber || !phoneNumber.isValid() || !phoneNumber.isPossible()) {
      return { valid: false, message: 'Please enter a valid phone number.' }
    }

    if (phoneNumber.country !== 'US' && phoneNumber.country !== 'CA') {
      return {
        valid: false,
        message: 'Only US and Canada numbers are supported.',
      }
    }

    return { valid: true, message: 'Valid phone number.' }
  }