import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import isEmail from 'validator/lib/isEmail'
import queryString from 'query-string'

import api from 'services/pet-notify-api'
import { createTempUser } from '../../../../store/signup/actions'
import SignupUserInfoScreen from '../../../../screens/Signup/Premium/UserInfo'
import useCaptureSignupParams from '../../../../hooks/useCaptureSignupParams'
import { validatePhoneNumber } from 'services/validate-phone-number'

type Props = {
  user?: SignupUserInfo
  promoCode?: string
}

function SignupUserInfoScreenContainer(props: Props & RouteComponentProps) {
  const dispatch = useDispatch()
  const { user, history, promoCode } = props
  const [isLoading, setIsLoading] = useState(true)
  const [pricing, setPricing] = useState()
  const [phoneNumberError, setPhoneNumberError]: any = useState(null)
  const [isCaptchaSuccessful, setIsCaptchaSuccessful] = useState(false)
  function handleCaptcha() {
    setIsCaptchaSuccessful(true)
  }

  useEffect(() => {
    api.orders.pricing({ promoCode }).then((result) => {
      setPricing(result)
      setIsLoading(false)
    })
  }, [])

  const isCapturingSignupParams = useCaptureSignupParams(location.search)

  const { plan } = queryString.parse(location.search)

  async function handleSubmit(data: SignupUserInfo) {
    setIsLoading(true)

    try {
      // if (!data.agree) {
      //   throw new Error(
      //     'Please verify you understand that Pet Notify is only available in the US and Canada',
      //   )
      // }
      if (!isCaptchaSuccessful) {
        throw new Error('Captcha is required')
      }
      if (!data.firstName) {
        throw new Error('First name required')
      }
      if (!data.lastName || data.lastName === '') {
        throw new Error('Last name required')
      }
      if (!data.email || data.email === '') {
        throw new Error('Email is required')
      }
      if (!isEmail(data.email)) {
        throw new Error('Email must be a valid email address')
      }
      if (!data.policyAcceptance) {
        throw new Error(
          'Please verify you understand the User Agreement and Privacy Policy',
        )
      }
      if (!data.smsPermission) {
        throw new Error(
          'Please verify you agree to receive SMS alerts.',
        )
      }
      if (data.email !== data.confirmEmail) {
        throw new Error('Your emails do not match')
      }
      if (!data.phone) {
        throw new Error('Phone number is required')
      }

      const validation = validatePhoneNumber(data.phone)

      if (!validation.valid) {
        throw new Error(validation.message)
      }

      if (data.phone) {
        try {
          const response: any = await api.signup.isPhoneAvailable({ phone: data.phone })
          if (!response.isPhoneAvailable) {
            setPhoneNumberError('An account with this phone number already exists. Please log in using the Pet Notify mobile app or visit https://app.petnotify.com/signin. You can also reset your password using your registered email if needed.')
            setIsLoading(false)
            return
          } else {
            setPhoneNumberError(null)
          }

        } catch (error) {
          throw new Error('Phone number already in use')
        }
      }

      setIsLoading(false)
      await dispatch(
        createTempUser({
          ...user,
          ...data,
          promoCode,
          plan: plan === 'yearly' ? 'year' : 'month',
        }),
      )
      history.push('/signup/premium/intellitag')
    } catch (e) {
      setIsLoading(false)
      throw e
    }
  }

  return (
    <SignupUserInfoScreen
      onSubmit={handleSubmit}
      isLoading={isLoading || isCapturingSignupParams}
      initialValues={user}
      hasPromoCode={!!promoCode}
      plan={plan}
      pricing={pricing}
      phoneNumberError={phoneNumberError}
      onCaptcha={handleCaptcha}
    />
  )
}

type ReduxState = {
  signup: {
    user?: UserInfo
  }
}

export default connect((state: State) => ({
  user: state.signup.user,
  promoCode: state.promoCode && state.promoCode.code,
}))(SignupUserInfoScreenContainer)
