import api from 'lib/api'
import type {
  CheckoutFormData,
  ReplaceIntellitagOrder,
  ReplaceIntellitagOrderPricing,
  RequestData,
} from './types'

export function fetchOne(id: string) {
  return api
    .get<ReplaceIntellitagOrder>(`/api/replace-intellitag-orders/${id}`)
    .then(({ data }) => data)
}

export function fetchPricing(id: string) {
  return api
    .get<ReplaceIntellitagOrderPricing>(
      `/api/replace-intellitag-orders/${id}/pricing`,
    )
    .then(({ data }) => data)
}

export function update(id: string, data: RequestData) {
  return api
    .put<ReplaceIntellitagOrder>(`/api/replace-intellitag-orders/${id}`, data)
    .then(({ data }) => data)
}

export function checkout(id: string, data: CheckoutFormData) {
  return api
    .patch<ReplaceIntellitagOrder>(
      `/api/replace-intellitag-orders/${id}/checkout`,
      data,
    )
    .then(({ data }) => data)
}

export function create(data: RequestData) {
  return api
    .post<ReplaceIntellitagOrder>(`/api/replace-intellitag-orders`, data)
    .then(({ data }) => data)
}

export function cancel(id: string) {
  return api
    .delete<ReplaceIntellitagOrder>(`/api/replace-intellitag-orders/${id}/cancel`)
    .then(({ data }) => data)
}

export function cancelOrder(id: string) {
  return api
    .delete<void>(`/api/intellitag-orders/${id}/cancel`)
    .then(({ data }) => data)
}

export function cancelFreshOrder(id: string) {
  return api
    .delete<void>(`/api/intellitag-orders/${id}/cancel-order`)
    .then(({ data }) => data)
}



