import { Button, Input, Label, Modal, Text } from '@pet-notify/ui'
import HeaderOne from 'components/Headers/HeaderOne'
import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import classNames from 'classnames'
import useUser from 'UserApp/hooks/useUser'
import petNotifyApi from 'services/pet-notify-api'
import useNotification from 'UserApp/hooks/useNotification'
import { fetch } from 'UserApp/store/user/actions'
import { useDispatch } from 'react-redux'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { validatePhoneNumber } from 'services/validate-phone-number'

const Alert = () => {
  const { user } = useUser()
  const { setNotification } = useNotification()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [showOtpInput, setShowOtpInput] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const phoneNumber = user?.phone?.replaceAll('-', '')
    setPhoneNumber(phoneNumber)
  }, [user])

  const sendOtp = async () => {
    const validation = validatePhoneNumber(phoneNumber)

    if (!validation.valid) {
      setNotification(validation.message)
      return
    }

    const response = await petNotifyApi.users.sendOtpForPhoneVerification({
      email: user.email,
      phone: phoneNumber,
    })

    if (response.statusCode === 200 && response.success) {
      setShowOtpInput(true)
      setNotification('OTP sent to phone number')
    } else {
      setNotification(response.message)
    }
  }

  const verifyOtp = async () => {
    const response = await petNotifyApi.users.verifyOtpForPhoneVerification({
      email: user.email,
      phone: phoneNumber,
      otp: otp,
    })

    if (response.success == false) {
      setNotification(response.message)
    } else {
      setIsModalOpen(false)
      fetchUser().then(() => {
        setNotification('Phone number verified successfully')
      })
    }
  }

  async function fetchUser() {
    await dispatch(fetch())
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#019BD1',
          color: 'white',
          marginTop: '1px',
          paddingLeft: '70px',
        }}
      >
        Your phone number is not verified. Please verify your phone number to
        continue using the app.&nbsp;
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => setIsModalOpen(true)}
        >
          Click Here
        </span>
      </div>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className={classNames(style.wrapper, style.expired)}>
            <HeaderOne className={style.header}>Verify Phone Number</HeaderOne>

            <Text
              className={style.text}
              style={{ fontSize: '16px', lineHeight: '22px', color: '#828282' }}
            >
              To ensure secure access and important communications, please enter
              a valid mobile number. This number will be used for OTP
              verification and is essential for pet emergency alarm
              notifications. Using a landline or incorrect contact number will
              result restricted access to Pet Notify access.
            </Text>

            <div style={{ display: 'flex', alignItems: 'end', gap: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  width: '100%',
                }}
              >
                <Label htmlFor='phoneNumber'>Phone Number</Label>
                <Input
                  type='text'
                  defaultValue={phoneNumber}
                  id='phoneNumber'
                  style={{ width: '100%' }}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <Button onClick={sendOtp} variant='red' className={style.button}>
                Send OTP
              </Button>
            </div>

            {showOtpInput && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: '16px',
                  marginTop: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    width: '100%',
                  }}
                >
                  <Label htmlFor='otp'>Enter OTP</Label>
                  <Input
                    type='number'
                    id='otp'
                    style={{ width: '100%' }}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>

                <Button
                  onClick={verifyOtp}
                  variant='red'
                  className={style.button}
                >
                  Verify OTP
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default Alert
