import React, { useState } from 'react'
import classNames from 'classnames'
import queryString from 'query-string'

import Modal from 'sharedComponents/Modal'

import style from './style.module.scss'
import { useCancelFreshOrder, useCancelIntellitagOrder, useCancelReplaceIntellitagOrder } from 'UserApp/hooks/api/replace-intellitag-order'
import api from 'services/pet-notify-api'


function DeleteIntellitagModal(props: any) {
    const { onClose, intellitagId, petName, petId } = props
    const isSuccessPage = props.location.pathname === `/order-intellitag/success`
    const { mobile } = queryString.parse(location.search)
    const isMobile = mobile === 'true'
    const [isDeleting, setIsDeleting] = useState(false)

    const handleDeleteIntellitag = async () => {
        try {
            setIsDeleting(true)
            const response = await api.pets.deleteIntellitag(intellitagId, petId)
            console.log(response)
            onClose()
        } catch (error) {
            console.error('Error deleting intellitag:', error)
        } finally {
            setIsDeleting(false)
        }
    }
    return (
        <Modal
            onClose={onClose}
            showCloseButton={!isSuccessPage}
            className={classNames(style.wrapper, {
                [style.successModal]: isSuccessPage,
                [style.isFullscreen]: isMobile,
            })}
            disableClickOffToClose
            isFullscreen={isMobile}
        >
            <div className={style.content}>
                <h2>Delete Intellitag</h2>
                <p style={{ marginBottom: '10px' }}>You’re requesting to delete intellitag #{intellitagId} for {petName}.</p>
                <p>Click DELETE to confirm</p>

                <div className={style.buttons}>
                    <button
                        className={style.cancelButton}
                        onClick={onClose}
                        disabled={isDeleting}
                    >
                        No, Keep Intellitag
                    </button>
                    <button
                        className={style.confirmButton}
                        disabled={isDeleting}
                        onClick={handleDeleteIntellitag}
                    >
                       Yes, Delete Intellitag
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteIntellitagModal