import React from 'react'
import { connect } from 'react-redux'
import DeleteIntellitagModal from './DeleteIntellitagModal'
import queryString from 'query-string'

function DeleteIntellitagModalContainer(props: any) {
  const { intellitag, name, petId } = queryString.parse(location.search)
  function handleClose() {
    props.history.push(`/pets/${petId}`)
  }
  return <DeleteIntellitagModal {...props} intellitagId={intellitag} petName={name} onClose={handleClose} petId={petId} />
}
export default connect()(DeleteIntellitagModalContainer)

