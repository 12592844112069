import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'

import { setIntellitags } from 'UserApp/store/intellitagOrder/actions'
import { selectAllPets, selectPetsWithoutIntellitags } from 'UserApp/store/pets/selectors'
import useIntellitags from 'UserApp/hooks/useIntellitags'
import Intellitag from 'UserApp/components/OrderIntellitagModal/Intellitag'
import { Redirect } from 'react-router'

function IntellitagContainer(props) {
  const { setIntellitags, pets, isMobile } = props
  const { petId } = queryString.parse(props.location.search)
  const { intellitags, onChange, onAdd, onRemove } = useIntellitags(
    pets,
    petId && pets.findIndex((pet) => pet.id === Number(petId)),
  )

  const [wasSubmitted, setWasSubmitted] = useState(false)

  async function handleSubmit(event) {
    const intellitagsForApi = intellitags.map((intellitag) => {
      const { size, phone, weight } = intellitag
      const pet = pets[intellitag.pet]

      return {
        size,
        weight,
        petId: pet.id,
        nameOnTag: intellitag.name,
        phone,
      }
    })
    setIntellitags(intellitagsForApi)
    setWasSubmitted(true)
  }

  if (pets.length === 0) {
    window.location.href = '/';
    return null
  }

  return (
    <Intellitag
      isMobile={isMobile}
      intellitags={intellitags}
      onSubmit={handleSubmit}
      onAdd={onAdd}
      onRemove={onRemove}
      onChange={onChange}
      wasSubmitted={wasSubmitted}
      pets={pets}
    />
  )
}

export default connect(
  (state) => ({
    intellitags: state.intellitagOrder.intellitags,
    pets: selectAllPets(state),
  }),
  { setIntellitags },
)(IntellitagContainer)
