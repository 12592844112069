import React from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Label,
  Input,
  Button,
  ErrorText,
  Checkbox,
} from 'components/Forms'
import Loader from 'components/Loader'
import HeaderOne from 'components/Headers/HeaderOne'
import Link from 'components/Link'
import Text from 'components/Text'
import { ContactType } from 'types'

import BackLink from 'UserApp/components/BackLink'

import style from './style.module.scss'

ContactForm.propTypes = {
  didResendInvite: PropTypes.bool,
  isConfirmingRemove: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onCancelRemove: PropTypes.func,
  onConfirmRemove: PropTypes.func,
  onGoBack: PropTypes.func,
  onRemove: PropTypes.func,
  onResendInvite: PropTypes.func,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.shape(ContactType),
  customError: PropTypes.string,
}

function ContactForm(props) {
  const {
    didResendInvite,
    isConfirmingRemove,
    isEditing,
    isLoading,
    isProcessing,
    onCancelRemove,
    onConfirmRemove,
    onGoBack,
    onRemove,
    onResendInvite,
    onSkip,
    onSubmit,
    title,
    values,
    customError,
  } = props

  if (isLoading) {
    return <Loader />
  }

  const { email, isPending, isDeclined } = values

  return (
    <div className={style.wrapper}>
      {onGoBack && (
        <BackLink onClick={onGoBack} className={style.backLink}>
          Back
        </BackLink>
      )}
      <HeaderOne className={style.header}>
        {title ? `${title}` : isEditing ? `Edit contact` : `Add contact`}
      </HeaderOne>
      {(isPending || isDeclined) && (
        <div className={style.invitationStatus}>
          <Text className={style.text}>
            Your email invitiation to {email}{' '}
            {isPending ? `is pending` : `was declined`}.
          </Text>
          {didResendInvite ? (
            <Text className={style.note} variant='note'>
              Your invite was resent
            </Text>
          ) : (
            <Link onClick={onResendInvite} className={style.link}>
              Resend invite
            </Link>
          )}
        </div>
      )}
      <Form onSubmit={onSubmit} values={values}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Label htmlFor='contact-first-name'>First Name</Label>
              <Input
                name='firstName'
                id='contact-first-name'
                placeholder='Required'
                value={values.firstName}
                onChange={onChange}
              />
              <Label htmlFor='contact-last-name'>Last Name</Label>
              <Input
                type='text'
                name='lastName'
                id='contact-last-name'
                placeholder='Required'
                value={values.lastName}
                onChange={onChange}
              />
              <Label htmlFor='contact-email'>Email</Label>
              <Input
                type='email'
                name='email'
                placeholder='Required'
                id='contact-email'
                value={values.email}
                onChange={onChange}
              />
              {/* {values.phone && (
                <> */}
              <Label htmlFor='contact-phone'>Mobile phone</Label>
              {isEditing ? 
                <Input
                  type='phone'
                  name='phone'
                  placeholder='Required'
                  id='contact-phone'
                  value={values.phone}
                  onChange={onChange}
                  disabled
                /> :
                <Input
                  type='phone'
                  name='phone'
                  placeholder='Required'
                  id='contact-phone'
                  value={values.phone}
                  onChange={onChange}
                />
              }
                {/* </>
              )} */}

              {/* <Checkbox
                name='isEmergencyContact'
                value={values.isEmergencyContact}
                onChange={onChange}
                label='Emergency contact'
                id='contact-is-emergency-contact'
              /> */}
              <Text variant='note' className={style.emergencyNote}>
                Emergency contacts will be notified when your alarm goes off,
                and all contacts can be assigned tasks.
              </Text>

              {customError && <ErrorText>{customError}</ErrorText>}

              {isEditing ? (
                isConfirmingRemove ? (
                  <React.Fragment>
                    <Text>Are you sure you want to remove this contact?</Text>
                    <Button
                      onClick={onCancelRemove}
                      variant='hollowRed'
                      className={style.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onConfirmRemove}
                      variant='red'
                      className={style.button}
                    >
                      Remove
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button type='submit' className={style.button}>
                      Save
                    </Button>
                    <Button onClick={onRemove} variant='hollowRed'>
                      Remove Contact
                    </Button>
                  </React.Fragment>
                )
              ) : (
                <Button type='submit' className={style.button}>
                  Invite and continue
                </Button>
              )}

              {onSkip && <Link onClick={onSkip}>Skip, I’ll do it later</Link>}
            </React.Fragment>
          )
        }
      </Form>
    </div>
  )
}

export default ContactForm
