import React, { useState } from 'react'
import classNames from 'classnames'
import queryString from 'query-string'

import Modal from 'sharedComponents/Modal'

import style from './style.module.scss'
import { useCancelIntellitagOrder, useCancelReplaceIntellitagOrder } from 'UserApp/hooks/api/replace-intellitag-order'
import CancelReplacementOrderSuccess from './CancelReplacementOrderSuccess'
import { Button } from '@pet-notify/ui'

function CancelReplacementIntellitagOrderModal(props: any) {
    const { onClose, petId, orderId } = props
    const isSuccessPage = props.location.pathname === `/order-intellitag/success`
    const { mobile } = queryString.parse(location.search)
    const isMobile = mobile === 'true'

    const isMultipalOrders = orderId?.split(',') ?? []
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(isMultipalOrders.length == 1 ? isMultipalOrders[0] : null)
    const [isOrderSelected, setIsOrderSelected] = useState(false)

    const cancelReplaceIntellitagOrderMutation = useCancelReplaceIntellitagOrder();
    const [isCancelled, setIsCancelled] = useState(false)
    return (
        <Modal
            onClose={onClose}
            showCloseButton={!isSuccessPage}
            className={classNames(style.wrapper, {
                [style.successModal]: isSuccessPage,
                [style.isFullscreen]: isMobile,
            })}
            disableClickOffToClose
            isFullscreen={isMobile}
        >
            {
                isMultipalOrders.length > 1 && !isOrderSelected ? (
                    <div className={style.content}>
                        <h2>Cancel Replacement Order</h2>
                        <p>Select which replacement order you would like to cancel:</p>
                        <select
                            className={style.orderSelect}
                            onChange={(e) => {
                                const selectedOrderId = e.target.value;
                                setSelectedOrderId(selectedOrderId)
                            }}
                        >
                            <option value="">Select an order...</option>
                            {isMultipalOrders.map((orderId: string) => (
                                <option key={orderId} value={orderId}>
                                    Order #{orderId}
                                </option>
                            ))}
                        </select>
                        <div className={style.buttons}>
                            <Button onClick={onClose}>Close</Button>
                            <Button onClick={() => {
                                if (selectedOrderId) {
                                    setIsOrderSelected(true)
                                }
                            }}>Proceed</Button>
                        </div>
                    </div>
                ) : (isCancelled ? (
                    <CancelReplacementOrderSuccess onClose={onClose} orderId={orderId} />
                ) : (
                    <div className={style.content}>
                        <h2>Cancel Replacement Order</h2>
                        <p>Are you sure you want to cancel your Intellitag replacement order?</p>

                        <div className={style.buttons}>
                            <button
                                className={style.cancelButton}
                                onClick={onClose}
                                disabled={cancelReplaceIntellitagOrderMutation.isLoading}
                            >
                                No, Keep Order
                            </button>
                            <button
                                className={style.confirmButton}
                                disabled={cancelReplaceIntellitagOrderMutation.isLoading}
                                onClick={() => {
                                    cancelReplaceIntellitagOrderMutation.mutate(selectedOrderId!, {
                                        onSuccess: () => {
                                            setIsCancelled(true)
                                        }
                                    })
                                }}
                            >
                                {cancelReplaceIntellitagOrderMutation.isLoading ? 'Canceling...' : 'Yes, Cancel Order'}
                            </button>
                        </div>
                    </div>
                ))
            }
        </Modal>
    )
}

export default CancelReplacementIntellitagOrderModal