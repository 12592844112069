import React, { useState } from 'react'
import numeral from 'numeral'

import Layout from 'sharedComponents/Layout/Split'
import Loader from 'sharedComponents/Loader'
import Form from 'sharedComponents/Form'
import Button from 'sharedComponents/Button'
import Label from 'sharedComponents/Label'
import Checkbox from 'sharedComponents/Checkbox'
import Error from 'sharedComponents/Error'
import Input from 'sharedComponents/Input'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Steps from 'UserApp/components/Steps'
import PromoCode from 'UserApp/components/InlinePromoCode'
import MobileHeaderImage from '../../MobileHeaderImage'
import Link from 'sharedComponents/Link'
import style from './style.module.scss'
import PremiumFeaturesModal from 'UserApp/components/PremiumFeaturesModal'
import mixPanel, { EVENTS } from 'services/mix-panel'
import ReCAPTCHA from 'react-google-recaptcha'
import { RECAPTCHA_SITE_KEY } from 'config'

type Props = {
  pricing?: Pricing
  onSubmit: (userInfo: SignupUserInfo) => Promise<void>
  isLoading: boolean
  initialValues?: SignupUserInfo
  hasPromoCode: boolean
  plan: string
  phoneNumberError: string | null
  onCaptcha: () => void
}

function SignupUserInfoScreen(props: Props) {
  const { onSubmit, isLoading, initialValues, plan, pricing, phoneNumberError, onCaptcha } = props

  const [isShowingModal, setIsShowingModal] = useState(false)

  return (
    <>
      <Layout>
        <MobileHeaderImage step={1} />
        <Steps currentStep={1} totalSteps={4} />
        <HeaderOne className={style.title}>Welcome to Pet Notify!</HeaderOne>
        <Text className={style.text}>
          Create your account to customize your Intellitag and start
          protecting your pets.
        </Text>
        <Link onClick={() => setIsShowingModal(true)} className={style.link}>
          What else does Premium include?
        </Link>
        <Form<SignupUserInfo>
          onSubmit={onSubmit}
          initialValues={initialValues}
          className={style.form}
        >
          {({ onChange, values, error }) =>
            isLoading ? (
              <Loader />
            ) : (
              <>
                <Label htmlFor='signup-first-name'>First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  id='signup-first-name'
                  value={values.firstName}
                  onChange={onChange}
                />
                <Label htmlFor='signup-last-name'>Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  id='signup-last-name'
                  value={values.lastName}
                  onChange={onChange}
                />

                <Label htmlFor='phone-number'>Phone number</Label>
                <Input
                  type='number'
                  name='phone'
                  id='phone-number'
                  value={values.phone}
                  onChange={onChange}
                />
                {phoneNumberError && <Error>{phoneNumberError}</Error>}

                <Label htmlFor='signup-email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='signup-email'
                  value={values.email}
                  onChange={onChange}
                />

                <Label htmlFor='signup-confirm-email'>Confirm email</Label>
                <Input
                  type='email'
                  name='confirmEmail'
                  id='signup-confirm-email'
                  value={values.confirmEmail}
                  onChange={onChange}
                />



                <div className={style.captcha}>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onCaptcha}
                  />
                </div>

                {/* <Text className={style.bottomText}>Terms of Use: <a href="https://www.petnotify.com/terms-of-use">https://www.petnotify.com/terms-of-use</a></Text>   */}
                {/* <Text className={style.bottomText}>Privacy Policy: <a href="https://www.petnotify.com/privacy-policy"> https://www.petnotify.com/privacy-policy</a></Text>   */}
                <div className={style.policyContainer}>
                  <Checkbox
                    name='policyAcceptance'
                    value={values.policyAcceptance}
                    onChange={onChange}
                    label=''
                    id='signup-policy-acceptance'
                  />
                  <Text className={style.linkText}>
                    By signing up I agree to Pet Notify's{' '}
                    <a href="https://www.petnotify.com/terms-of-use" className={style.link}>
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a href="https://www.petnotify.com/privacy-policy" className={style.link}>
                      Privacy Policy
                    </a>
                  </Text>
                </div>
                <div className={style.policyContainer} style={{ marginBottom: '26px' }}>
                  <Checkbox
                    name='smsPermission'
                    value={values.smsPermission}
                    onChange={onChange}
                    label=''
                    id='singup-sms-permission'
                  />
                  <Text className={style.linkText}>
                    By signing up, you agree to receive SMS alerts related to your Pet Notify account and services. You can opt out at any time by replying STOP.
                  </Text>
                </div>

                {error && <Error>{error}</Error>}

                <Button
                  type='submit'
                  onClick={() =>
                    mixPanel.track(EVENTS.PREMIUM_CREATE_ACCOUNT_CLICK)
                  }
                  style={{marginRight: '16px'}}
                >
                  Create account
                </Button>

                <Button variant='hollow' href='/activate/2' className={style.button} style={{marginLeft: '0'}}>
                  Already purchased an Intellitag?
                </Button>
              </>
            )
          }
        </Form>

        <PromoCode isAnnualPlanSelected={plan === 'yearly'} width='50'/>

        {!isLoading && (
          <ul className={style.notes}>
            <li>No commitments, cancel anytime</li>
            <li>
              Only one subscription is required per household and includes
              unlimited pets
            </li>
          </ul>
        )}

        {isShowingModal && (
          <PremiumFeaturesModal onClose={() => setIsShowingModal(false)} />
        )}
      </Layout>
      <img
        src='https://pubads.g.doubleclick.net/activity;xsp=4691457;ord=[session id]?'
        width='1'
        height='1'
        border='0'
      />
    </>
  )
}

export default SignupUserInfoScreen
