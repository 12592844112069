import { createSelector } from 'reselect'

const selectEntities = (state) => state.contacts.entities
const selectResult = (state) => state.contacts.result

export const selectAll = createSelector(
  [selectEntities, selectResult],
  (entities, result) => result.map((id) => entities[id]),
)

export const selectAllEmergency = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result
      .map((id) => entities[id]),
)

export const selectAllEmergencyAndApproved = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result
      .map((id) => entities[id])
      .filter(
        (entity) =>
          entity.isEmergencyContact && entity.inviteStatus === 'accepted',
      ),
)

export const selectAllOther = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result
      .map((id) => entities[id])
      .filter((entity) => !entity.isEmergencyContact),
)

export const selectOne = (state, props) => state.contacts.entities[props.id]
