import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Header from './Header'
import Footer from './Footer'
import Navigation from './Navigation'
import HeaderRow from 'UserApp/components/Headers/HeaderRow'
import HeaderOne from 'components/Headers/HeaderOne'
import HeaderButton from 'UserApp/components/Headers/HeaderButton'
import FoundPetAlert from 'UserApp/containers/FoundPetAlert'

import style from './style.module.scss'
import Alert from './Alert'

AppLayout.propTypes = {
  children: PropTypes.node,
  showNavigation: PropTypes.bool,
  title: PropTypes.string,
  button: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string,
  }),
  banner: PropTypes.node,
}

AppLayout.defaultProps = {
  showNavigation: true,
}

function AppLayout(props) {
  const {
    children,
    showNavigation,
    title,
    button,
    banner,
    customButton,
    alert,
  } = props
  const [isMenuExpanded, setIsMenuExpaneded] = useState(false)

  function handleToggleMenu() {
    setIsMenuExpaneded((isMenuExpanded) => !isMenuExpanded)
  }

  function handleClickLink() {
    setIsMenuExpaneded(false)
  }

  return (
    <div className={style.wrapper}>
      <Header onToggleMenu={handleToggleMenu} showNavigation={showNavigation} />
      <Navigation
        showNavigation={showNavigation}
        isExpanded={isMenuExpanded}
        onClickLink={handleClickLink}
      />
      <div className={classNames(style.inner)}>
        {title && (
          <>
            <HeaderRow className={style.headerRow}>
              <HeaderOne className={style.header}>{title}</HeaderOne>
              {button && (
                <HeaderButton to={button.to} small>
                  {button.text}
                </HeaderButton>
              )}
              {customButton && customButton()}
            </HeaderRow>
            {alert && <Alert alert={alert} />}
          </>
        )}

        {banner && banner()}

        <div className={classNames(style.mainContent, style.content)}>
          {children}
        </div>
        <Footer className={style.content} />
      </div>
      <FoundPetAlert />
    </div>
  )
}

export default AppLayout
