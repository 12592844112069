import React from 'react'
import { Button, Header, Text } from '@pet-notify/ui'
import style from './style.module.scss'

function CancelReplacementOrderSuccess({ onClose, orderId }: { onClose: () => void, orderId: string }) {
  return (
    <div className={style.content}>
      <Header mb="6">Replacement Order Cancelled</Header>
      <Text>
        Your Intellitag replacement order has been successfully cancelled.
      </Text>
      <Text>
        Any payments received will be credited to your card within 3-5 days.
      </Text>
      <Button onClick={onClose}>Close</Button>  
    </div>
  )
}

export default CancelReplacementOrderSuccess

